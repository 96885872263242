import "./styles/Home.css";

export default function Home() {
  return (
    <main className="main">

      <div className="footer">
        TrueDeBankers. 2023
      </div>
    </main>
  );
}